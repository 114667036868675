<template>
  <!-- 课程表 -->
  <div>
    <div
      style="display: flex; justify-content: end; padding-bottom: 16px"
      v-if="showClose"
    >
      <el-button
        icon="el-icon-close"
        plain
        circle
        size="mini"
        @click="$router.back()"
      ></el-button>
    </div>
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 16px;
      "
    >
      <i
        @click="
          () => {
            this.timestamp -= this.oneWeekMilliseconds
            this.getClassScheduleFunc()
          }
        "
        class="el-icon-arrow-left"
        style="cursor: pointer; padding: 0 20px"
      ></i>
      {{ timeList[0] + '~' + timeList[1] }}
      <i
        @click="
          () => {
            this.timestamp += this.oneWeekMilliseconds
            this.getClassScheduleFunc()
          }
        "
        class="el-icon-arrow-right"
        style="cursor: pointer; padding: 0 20px"
      ></i>
    </div>
    <div class="curriculumBox">
      <!-- 节 -->
      <div>
        <div v-for="i in section" :key="i">
          {{ i - 1 !== 0 ? '第' + (i - 1) + '节' : '' }}
        </div>
      </div>
      <div v-for="(item, i) in DataList" :key="i">
        <div style="text-align: center">
          <div>{{ item.formattedWeek }}</div>
          <div>{{ item.formattedDate }}</div>
        </div>
        <div
          v-for="(val, j) in item.crsOpenPlanSysSchoolClassSessionList"
          :key="j"
        >
          <template
            v-if="
              val.crsOpenPlanLessonList == null ||
              val.crsOpenPlanLessonList.length == 0
            "
          >
            <div class="voidBox">
              <img src="@/assets/homepage/icon_course_calendar.png" />
              <span>暂无课程</span>
            </div>
          </template>
          <template v-else>
            <div v-for="(e, z) in val.crsOpenPlanLessonList" :key="z">
              <div class="lessonsName ellipsis-2">{{ e.crsExpCourseName }}</div>
              <div class="lessonsInfo ellipsis">
                <img src="@/assets/homepage/icon_course_teacher.png" />
                {{ e.teacherName }}
              </div>
              <div class="lessonsInfo ellipsis">
                <img src="@/assets/homepage/icon_course_class.png" />
                {{ e.sysOrgSchoolClassName }}
              </div>
              <div class="lessonsInfo ellipsis" style="margin-bottom: 10px;">
                <img src="@/assets/homepage/icon_course_position.png" />
                {{ e.sysOrgSchoolRoomName }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { getClassSchedule } from '@/api/courseStart/scheduleLessonsManage.js'
export default {
  name: 'curriculum',
  data() {
    return {
      timestamp: new Date().getTime(),
      DataList: [],
      section: '',
      timeList: [],
      oneWeekMilliseconds: 7 * 24 * 60 * 60 * 1000,
      showClose: true,
      dataList: {},
    }
  },
  async created() {
    this.dataList = {
      crsOpenPlanId: this.$route.query?.crsOpenPlanId,
      schoolSectionDicId: this.$route.query?.schoolSectionDicId,
      sysOrgSchoolClassId: this.$route.query?.sysOrgSchoolClassId,
      sysOrgSchoolId: this.$route.query?.sysOrgSchoolId,
      sysSchoolClassGradeId: this.$route.query?.sysSchoolClassGradeId,
      sysSchoolTermId: this.$route.query?.sysSchoolTermId,
      sysSubjectDicId: this.$route.query?.sysSubjectDicId,
    }
    this.dataList = Object.entries(this.dataList).reduce(
      (acc, [key, value]) => {
        if (value) {
          acc[key] = value
        }
        return acc
      },
      {}
    )
  },
  mounted() {
    // this.section = 8
    this.getClassScheduleFunc(this.dataList)
  },
  methods: {
    getClassScheduleFunc(data = this.dataList) {
      getClassSchedule(this.timestamp, data)
        .then((res) => {
          console.log('课程表', res)
          if (res.success) {
            this.DataList = res.data
            this.section =
              res.data[0].crsOpenPlanSysSchoolClassSessionList.length + 1
            this.timeList = [
              res.data[0].formattedDate,
              res.data[res.data.length - 1].formattedDate,
            ]
          } else {
            this.$message.warning(res.msg)
          }
        })
        .catch((err) => {})
    },
    saveBtn() {
      this.$emit('saveBtnJump')
    },
  },
  watch: {},
  filters: {
    dayFilters(val) {
      // return val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
      return val ? dayjs(val).format('YYYY-MM-DD') : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.curriculumBox {
  display: flex;
  width: 100%;
  border-left: 1px solid #ccc;
  box-sizing: border-box;
  & > div {
    width: calc(100% / 8);
    border: 1px solid #ccc;
    border-left: 0;
    box-sizing: border-box;
    min-height: 120px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    & > div {
      text-align: center;
      box-sizing: border-box;
      height: 120px;
      overflow-y: auto;
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      border-bottom: 1px solid #ccc;
      &:last-child {
        border: 0;
      }
      & > div {
        width: 100%;
      }
    }
  }
}
.lessonsName {
  margin-bottom: 8px;
  width: 100%;
  font-size: 16px;
  text-align: left;
  padding-left: 10px;
  box-sizing: border-box;
}
.lessonsInfo {
  font-size: 14px;
  color: #586782;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  vertical-align: middle;
  text-align: left;
  padding-left: 10px;
  img {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
}
.voidBox {
  img {
    display: block;
    width: 42px;
    height: 42px;
    margin: 0 auto;
  }
  span {
    display: block;
    margin: 0 auto;
    margin-top: 10px;
    line-height: 14px;
    font-size: 14px;
    color: #dadcdf;
    text-align: center;
  }
}

// 美化滚动条
::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}
</style>
